import {
  addWeeks,
  endOfWeek,
  format,
  max,
  min,
  parse,
  parseISO,
  startOfWeek,
  subMilliseconds
} from 'date-fns'
import {
  fromZonedTime,
  getTimezoneOffset,
  toZonedTime,
  format as formatTz
} from 'date-fns-tz'

export const dateToUserTimezone = (
  date: string,
  timeZone: string,
  formatStr = 'yyyy-MM-dd HH:mm:ss'
) => {
  return format(fromZonedTime(convertDateToUTC(date), timeZone), formatStr)
}

export function subtractTimezone(date: string, timeZone: string) {
  const timezoneOffset = getTimezoneOffset(timeZone)

  return subMilliseconds(new Date(date), timezoneOffset)
    .toISOString()
    .replace('Z', '')
    .replace('T', ' ')
}

export const unixTimeToUserTimezone = (unixTime: number) => {
  return format(new Date(unixTime * 1000), 'PPp')
}

export const unixTimeToUTC = (unixTime: number) => {
  const utcDate = toZonedTime(new Date(unixTime * 1000), 'UTC')
  return formatTz(utcDate, 'PPp')
}
export const convertDateToUTC = (date: string) => {
  if (!date || (date.includes('Z') && date.includes('T'))) {
    return date
  }

  const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss.SSS', new Date())

  return format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
}

export const dateToUTC = (date, timeZone) => {
  const dateInUserTz = new Date(
    new Intl.DateTimeFormat('en-US', {
      timeZone: timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).format(new Date(date))
  )

  const utcOffset = dateInUserTz.getTimezoneOffset() * 60000
  return new Date(dateInUserTz.getTime() - utcOffset)
    .toISOString()
    .slice(0, 19)
    .replace('T', ' ')
}

export function calculateMonthDiff(dateString?: string | null) {
  if (!dateString) {
    return 0
  }

  const inputDate = new Date(dateString)

  const today = new Date()

  const yearsDiff = today.getFullYear() - inputDate.getFullYear()
  const monthsDiff = today.getMonth() - inputDate.getMonth()
  const totalMonthsDiff = yearsDiff * 12 + monthsDiff

  const remainingMonths = totalMonthsDiff % 12

  let output = ''
  if (yearsDiff > 0) {
    output += `${yearsDiff} year${yearsDiff > 1 ? 's' : ''} `
  }
  if (remainingMonths > 0) {
    output += `${remainingMonths} month${remainingMonths > 1 ? 's' : ''}`
  }

  return output.trim()
}

export const getMonthDiff = (dateString?: string | null) => {
  if (!dateString) {
    return 0
  }

  const inputDate = new Date(dateString)

  const today = new Date()

  const yearsDiff = today.getFullYear() - inputDate.getFullYear()
  const monthsDiff = today.getMonth() - inputDate.getMonth()
  const totalMonthsDiff = yearsDiff * 12 + monthsDiff

  if (totalMonthsDiff < 12) {
    return totalMonthsDiff === 1 ? '1 month' : `${totalMonthsDiff} mos`
  }

  if (totalMonthsDiff < 24) {
    return `12+ mos`
  }

  return `24+ mos`
}

export function calculateTimeDifference(
  date1: string | null | Date,
  date2: string | null | Date
) {
  if (!date1 || !date2) {
    return ''
  }

  // Normalize the dates to UTC
  const date1UTC = new Date(date1).getTime()
  const date2UTC = new Date(date2).getTime()

  const diffInMilliseconds = date2UTC - date1UTC

  // Ensure non-negative difference
  const absDiffInMilliseconds = Math.abs(diffInMilliseconds)

  const minutes = Math.floor(absDiffInMilliseconds / (1000 * 60))
  const seconds = Math.floor((absDiffInMilliseconds % (1000 * 60)) / 1000)

  // Format minutes and seconds
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  // TEST
  if (diffInMilliseconds < 0) {
    // console.log(
    //   `Date2 is before Date1 (time zone difference): ${date1}, ${date2}`
    // )
  }

  return `${formattedMinutes}:${formattedSeconds}`
}

export function getWeekRange({ weekNumber, year, rangeFrom, rangeTo }) {
  // Get the first day of the year
  const firstDayOfYear = new Date(year, 0, 1)

  // Find the first day of the requested week
  const startOfRequestedWeek = addWeeks(firstDayOfYear, weekNumber - 1)

  // Get the start and end of the week (assuming week starts on Monday)
  let from = startOfWeek(startOfRequestedWeek, { weekStartsOn: 1 })
  let to = endOfWeek(startOfRequestedWeek, { weekStartsOn: 1 })

  // Parse the provided rangeFrom and rangeTo into Date objects
  const parsedRangeFrom = parseISO(rangeFrom)
  const parsedRangeTo = parseISO(rangeTo)

  // Ensure the start date is not earlier than the rangeFrom and the end date is not later than the rangeTo
  from = max([from, parsedRangeFrom])
  to = min([to, parsedRangeTo])

  // Check if the month changes during the week
  const sameMonth = format(from, 'MMM') === format(to, 'MMM')

  // Format the dates conditionally based on whether the month changes
  let formattedFrom = format(from, sameMonth ? 'MMM d' : 'MMM d')
  let formattedTo = format(to, sameMonth ? 'd' : 'MMM d')

  // If the from and to dates are the same, just show one date
  if (format(from, 'yyyy-MM-dd') === format(to, 'yyyy-MM-dd')) {
    return formattedFrom
  }

  return `${formattedFrom} - ${formattedTo}, ${year}`
}

/**
 * Formats a timestamp in seconds into a MySQL-compatible TIMESTAMP string.
 * @param {number} timestamp - The timestamp in seconds to format.
 * @returns {string | null} - The formatted MySQL TIMESTAMP string or null if the input is invalid.
 */
export function convertTimestampToMySQLDate(timestamp: number): string {
  // Convert the timestamp from seconds to milliseconds
  const date = new Date(timestamp * 1000)

  const mysqlTimestamp = format(date, 'yyyy-MM-dd HH:mm:ss.SSS')

  return mysqlTimestamp
}
